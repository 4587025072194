import React from "react";

const AnimatedButton = (props) => {
  return (
    // <div className="hover:scale-[1.015] transition-transform duration-100">
    <div className="drop-shadow-none font-galada text-white bg-transparent hover:bg-theme rounded-full border-[3px] border-theme transition-transform duration-100">
      {props.children}
    </div>
  );
};

export default AnimatedButton;
