import React from "react";

import "./styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import {
  defaultChains,
  configureChains,
  createClient,
  WagmiConfig,
  chain,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";

import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import CustomAvatar from "./components/CustomAvatar";

const { chains, provider, webSocketProvider } = configureChains(
  [chain.mainnet],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "Surf and Turf NFT",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  // autoConnect: false,
  connectors,
  provider,
  webSocketProvider,
});

const App = () => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} avatar={CustomAvatar}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
export default App;
