import React from "react";

const MessageBox = (props) => {
  return (
    <React.Fragment>
      <div
        className={
          "fixed top-0 left-0 z-10 h-full w-full flex flex-col justify-center items-center bg-gray-800 bg-opacity-50 text-black" +
          (props.show ? " block" : " hidden")
        }
      >
        <div className="w-fit bg-white bg-opacity-90 border-[0px] rounded-3xl p-6 sm:p-6 lg:p-10 text-lg sm:text-xl lg:text-2xl text-center min-w-[360px]">
          <div className="mb-10 font-bold">{props.children}</div>
          <button
            className="w-full border-[3px] border-theme rounded-full px-5 pt-3 pb-3 text-md sm:text-lg lg:text-2xl font-bold "
            onClick={props.onOkClick}
          >
            OK
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MessageBox;
