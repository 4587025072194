import React, { useEffect, useRef, useState } from "react";
import MessageBox from "./MessageBox";
import logo from "../../assets/images/logo.svg";
import cage from "../../assets/images/phrase1_cage.gif";
import { ConnectButton, useConnectModal } from "@rainbow-me/rainbowkit";
import {
  useAccount,
  useBalance,
  useContractReads,
  useContractWrite,
} from "wagmi";
import { readContract } from "@wagmi/core";
import AnimatedButton from "../../components/AnimatedButton";
import SurfABI from "../../assets/abis/SurfABI";
import { BigNumber, ethers, utils } from "ethers";
import signature from "../../assets/whitelist/signature.json";

const CONTRACT_ADDRESS = "0xE1BFA55317BDf6063FA119EC98811b4AA40E29b0";
const CONTRACT_ABI = SurfABI;

const contract = {
  address: CONTRACT_ADDRESS,
  abi: CONTRACT_ABI,
};

function HomePage() {
  const setMessageBoxMessageOnError = (message) => {
    if (message.includes("presale is not active")) {
      setMessageBoxMessage("Presale is Not Active");
    } else if (message.includes("insufficient funds")) {
      setMessageBoxMessage("Insufficient Funds");
    } else if (message.includes("max supply reached")) {
      setMessageBoxMessage("Max Supply Reached");
    } else if (message.includes("quantity exceed maxMintPerWallet")) {
      setMessageBoxMessage("Quantity Exceed maxMintPerWallet");
    } else if (message.includes("can only presaleMint once")) {
      setMessageBoxMessage("Can Only PresaleMint Once");
    } else if (message.includes("can only mint with whitelist signature")) {
      setMessageBoxMessage("Invalid Whitelist Signature");
    } else if (message.includes("invalid signature")) {
      setMessageBoxMessage("Invalid Whitelist Signature");
    } else if (message.includes("can only publicMint once")) {
      setMessageBoxMessage("Can Only PublicMint Once");
    } else if (message.includes("ACTION_REJECTED")) {
      setMessageBoxMessage("User Rejected");
    } else {
      setMessageBoxMessage("Error Minting");
    }
    setIsShowMessageBox(true);
  };

  const {
    data: presaleMintData,
    isLoading: presaleMintIsLoading,
    isSuccess: presaleMintIsSuccess,
    write: presaleMintWrite,
  } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: "presaleMint",
    onError(error) {
      setMessageBoxMessageOnError(error.message);
    },
  });

  const {
    data: publicMintData,
    isLoading: publicMintIsLoading,
    isSuccess: publicMintIsSuccess,
    write: publicMintWrite,
  } = useContractWrite({
    mode: "recklesslyUnprepared",
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: "publicMint",
    onError(error) {
      setMessageBoxMessageOnError(error.message);
    },
  });

  const { data, isError, isLoading } = useContractReads({
    contracts: [
      {
        ...contract,
        functionName: "isPresaleActive",
      },
      {
        ...contract,
        functionName: "isSaleActive",
      },
      {
        ...contract,
        functionName: "maxSupply",
      },
      {
        ...contract,
        functionName: "totalSupply",
      },
      {
        ...contract,
        functionName: "mintPrice",
      },
      {
        ...contract,
        functionName: "maxMintPerWallet",
      },
    ],
  });

  const isPresaleActive = data ? data[0] : false;

  const isSaleActive = data ? data[1] : false;

  const maxSupply = data ? (data[2] ? data[2].toNumber() : 0) : 0; // collection size

  const totalSupply = data ? (data[3] ? data[3].toNumber() : 0) : 0; // minted count

  const defaultMintPrice = "0.019";
  const mintPrice = data
    ? data[4]
      ? ethers.utils.formatEther(data[4])
      : defaultMintPrice
    : defaultMintPrice; // string

  const maxMintPerWallet = data ? (data[5] ? data[5].toNumber() : 0) : 0;

  // console.log(data, isError, isLoading);
  // console.log(isPresaleActive, isSaleActive, maxSupply, totalSupply, mintPrice);

  const [messageBoxMessage, setMessageBoxMessage] = useState("Message Box");
  const [isShowMessageBox, setIsShowMessageBox] = useState(false);

  const { address, isConnected } = useAccount();
  const { data: balanceData } = useBalance({ address });
  const { openConnectModal } = useConnectModal();

  const [mintNum, setMintNum] = useState(1); // number of NFTs to be minted

  let whitelistSignature;
  if (address && signature && signature.hasOwnProperty(address.toLowerCase())) {
    whitelistSignature = signature[address.toLowerCase()];
  }

  const [isAddressPresaleMinted, setIsAddressPresaleMinted] = useState(false);

  useEffect(() => {
    if (!address) return;

    const readIsAddressPresaleMinted = async (_address) => {
      const isMinted = await readContract({
        address: CONTRACT_ADDRESS,
        abi: CONTRACT_ABI,
        functionName: "isAddressPresaleMinted",
        args: [_address],
      });
      // console.log(result);
      if (typeof isMinted === "boolean") setIsAddressPresaleMinted(isMinted);
    };
    readIsAddressPresaleMinted(address);
  }, [address]);

  const mintButtonOnClick = async (e) => {
    if (!isPresaleActive && !isSaleActive) return;

    if (presaleMintIsLoading || publicMintIsLoading) return;
    if (address && mintNum >= 1 && mintNum <= maxMintPerWallet) {
      try {
        const mintPriceWei = utils.parseUnits(mintPrice, "ether");
        const totalMintPriceWei = mintPriceWei.mul(mintNum);

        // console.log(mintPriceWei, totalMintPriceWei);

        if (!balanceData || !balanceData.value)
          throw "error fetching wallet balance";
        if (totalMintPriceWei.gt(balanceData.value)) throw "Not Enough ETH";

        if (
          (isPresaleActive && !isSaleActive) ||
          (isPresaleActive &&
            isSaleActive &&
            whitelistSignature &&
            !isAddressPresaleMinted)
        ) {
          if (!whitelistSignature) throw "No Whitelist";
          if (isAddressPresaleMinted) throw "Can Only PresaleMint Once";

          console.log(`PresaleMint with signature ${whitelistSignature}`);
          presaleMintWrite({
            recklesslySetUnpreparedArgs: [mintNum, whitelistSignature],
            recklesslySetUnpreparedOverrides: {
              value: totalMintPriceWei,
            },
          });
        }

        if (
          (!isPresaleActive && isSaleActive) ||
          (isPresaleActive &&
            isSaleActive &&
            (!whitelistSignature ||
              (whitelistSignature && isAddressPresaleMinted)))
        ) {
          publicMintWrite({
            recklesslySetUnpreparedArgs: [mintNum],
            recklesslySetUnpreparedOverrides: {
              value: totalMintPriceWei,
            },
          });
        }
      } catch (error) {
        console.log(error);
        setMessageBoxMessage(error);
        setIsShowMessageBox(true);
      }
    }
  };

  return (
    <main className="bg-[url('/src/assets/images/background-mobile.jpg')] md:bg-[url('/src/assets/images/background.jpg')] bg-cover bg-center">
      {/* Message Overlay */}
      <MessageBox
        show={isShowMessageBox}
        onOkClick={(e) => {
          setIsShowMessageBox((isShow) => {
            !isShow;
          });
        }}
      >
        {messageBoxMessage}
      </MessageBox>

      <div className="fixed z-10 top-0 right-0 pt-3 pr-3 xl:pt-6 xl:pr-6">
        {address && <ConnectButton accountStatus="address" />}
      </div>

      {isConnected && (
        <div className="z-0 fixed top-0 left-0 w-screen h-screen backdrop-blur-sm"></div>
      )}

      <div className="container relative z-0 flex flex-col min-h-[100vh] items-center justify-center p-4">
        {/* Main Component */}
        <div className="pt-8 pb-20 lg:pt-20 lg:pb-32 w-fit flex flex-col justify-center items-center text-center">
          {!isConnected && (
            <>
              <a
                className=""
                href=""
                // target="_blank"
                // rel="noopener noreferrer"
              >
                <img
                  src={logo}
                  className="mb-4 md:mb-6 lg:mb-16 h-[260px] sm:h-[300px] lg:h-[340px]"
                  alt="header-logo-img"
                />
              </a>
              <div className="drop-shadow-text text-white font-galada pt-1 pb-10 text-5xl sm:text-6xl lg:pb-14 lg:text-7xl">
                Mint Your Cage!
              </div>
              <div className="lg:pt-10">
                {/* <div className="text-3xl font-bold pb-10">0.01 ETH / Mint</div> */}
                <AnimatedButton>
                  <button
                    className="drop-shadow-text px-16 text-2xl px-12 pt-4 pb-2 md:px-16 md:pt-6 md:pb-4 lg:px-18 lg:pt-8 lg:pb-6 lg:w-fit md:text-3xl lg:text-4xl"
                    type="button"
                    onClick={openConnectModal}
                  >
                    <span className="">Connect</span>{" "}
                    <span className="">Wallet</span>
                  </button>
                </AnimatedButton>
              </div>
            </>
          )}

          {isConnected && (
            // Wallet connected
            <div className="flex flex-col md:flex-row md:gap-14 lg:gap-20 xl:gap-40">
              <div className="flex flex-col">
                <img
                  src={logo}
                  className="mt-12 mb-4 md:mb-6 lg:mb-14 h-[160px] md:h-[200px] lg:h-[220px]"
                  alt="header-logo-img"
                />

                <div className="drop-shadow-text text-white font-galada pt-1 pb-8 text-5xl sm:text-5xl lg:pb-12 lg:text-5xl">
                  Mint Your Cage!
                </div>
                <div className="drop-shadow-text font-galada">
                  <div className="text-4xl pb-6 lg:text-4xl">{`${totalSupply} / ${maxSupply} Cages`}</div>

                  <div className="text-2xl pb-6 lg:text-2xl">
                    {mintPrice} ETH / Surfer Minted
                  </div>

                  {/* Number of NFTs to be minted  */}
                  <div className="text-2xl mb-6 ">
                    Number of cage(s) to be Minted:
                  </div>

                  <div className="my-3 mb-8 flex flex-row justify-center items-center">
                    <button
                      className="w-10 hover:text-theme text-2xl"
                      onClick={(e) => {
                        if (mintNum > 1) {
                          setMintNum(mintNum - 1);
                        }
                      }}
                    >
                      ◀
                    </button>

                    <p className="px-4 pt-1 text-3xl lg:text-5xl">{mintNum}</p>

                    <button
                      className="w-10 hover:text-theme text-2xl"
                      onClick={(e) => {
                        if (mintNum < maxMintPerWallet) {
                          setMintNum(mintNum + 1);
                        }
                      }}
                    >
                      ▶
                    </button>
                  </div>
                </div>
                {/* Mint Button */}

                <AnimatedButton>
                  <button
                    className="drop-shadow-text px-16 text-2xl px-12 pt-4 pb-2 md:px-16 md:pt-6 md:pb-4 lg:px-18 lg:pt-8 lg:pb-6 lg:w-fit md:text-3xl lg:text-4xl"
                    type="button"
                    onClick={mintButtonOnClick}
                  >
                    {(presaleMintIsLoading || publicMintIsLoading) && (
                      <span>Minting...</span>
                    )}
                    {!presaleMintIsLoading && !publicMintIsLoading && (
                      <>
                        {!isPresaleActive && !isSaleActive && (
                          <span>Mint Inactive</span>
                        )}
                        {isPresaleActive && !isSaleActive && (
                          <span>Whitelist Mint</span>
                        )}
                        {!isPresaleActive && isSaleActive && (
                          <span>Mint Now</span>
                        )}

                        {isPresaleActive &&
                          isSaleActive &&
                          whitelistSignature &&
                          !isAddressPresaleMinted && (
                            <span>Whitelist Mint</span>
                          )}

                        {isPresaleActive &&
                          isSaleActive &&
                          (!whitelistSignature ||
                            (whitelistSignature && isAddressPresaleMinted)) && (
                            <span>Mint Now</span>
                          )}
                      </>
                    )}
                  </button>
                </AnimatedButton>
              </div>
              <div className="flex flex-col justify-center mt-24 md:mt-0">
                <div className="">
                  <img
                    src={cage}
                    alt=""
                    className="md:h-[300px] lg:h-[500px] xl:h-[700px] rounded rounded-xl lg:rounded-3xl shadow-[0_0_16px_16px_rgba(255,255,255,0.3)]"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
export default HomePage;
