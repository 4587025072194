import React from "react";

const CustomAvatar = ({ address, ensImage, size }) => {
  return ensImage ? (
    <img
      src={ensImage}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
    />
  ) : (
    <div
      className="bg-[url('/src/assets/images/icon.png')] bg-contain"
      style={{
        // backgroundColor: "black",
        borderRadius: 999,
        height: size,
        width: size,
      }}
    ></div>
  );
};

export default CustomAvatar;
